import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, {getLanguage} from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import common_en from "./translations/en/common.json"
import common_pl from "./translations/pl/common.json"
import common_ar from "./translations/ar/common.json"


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: getLanguage(),                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        pl: {
            common: common_pl
        },
        ar: {
            common: common_ar
        },
    },
});

root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
          <App/>
      </I18nextProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
