import React, {useState} from 'react';
import './App.css';
import {Input, Layout, Modal, Upload, UploadFile, UploadProps} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import Paragraph from "antd/es/typography/Paragraph";
import {PlusOutlined} from '@ant-design/icons';
import {RcFile} from "antd/es/upload";
import {useTranslation} from "react-i18next";
import Poland from "./img/poland.png";
import Egypt from "./img/egypt.png";
import UK from "./img/uk.png";

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const getLanguage = () => {
    const lang = window.navigator.language;

    if(lang.includes('pl')) {
        return 'pl';
    }

    if(lang.includes('ar')) {
        return 'ar';
    }

    return 'en';
}

function App() {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [lang, setLang] = useState(getLanguage());
    const [name, setName] = useState('anonymous');
    const [t, i18n] = useTranslation('common');

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        if (!file.type?.includes('video')) {
            setPreviewImage(file.url || (file.preview as string));
        } else {
            setPreviewImage('');
        }
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({fileList: newFileList}) =>
        setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>{t('send')}</div>
        </div>
    );

    const updateName = (event: any) => {
        setName(event?.target?.value ? event.target.value : 'anonymous');
        console.log(name)
    }

    return (
        <Layout>
            <Header className={'header'}>
                <img src={UK} className={'flag'} alt={'English'} onClick={() => {
                    i18n.changeLanguage('en').then(() => setLang('en'))
                }}/>
                <img src={Poland} className={'flag'} alt={'Polish'} onClick={() => {
                    i18n.changeLanguage('pl').then(() => setLang('pl'))
                }}/>
                <img src={Egypt} className={'flag'} alt={'Arabic'} onClick={() => {
                    i18n.changeLanguage('ar').then(() => setLang('ar'))
                }}/>
            </Header>
            <Content style={{background: 'white'}}>
                <div className={'content'}>
                    <Paragraph className={'paragraph'} style={{direction: lang === 'ar' ? 'rtl' : 'ltr'}}>{t('paragraph.first')}</Paragraph>
                    <Paragraph className={'paragraph'} style={{direction: lang === 'ar' ? 'rtl' : 'ltr'}}>{t('paragraph.second')}</Paragraph>
                    <Input placeholder={t('placeholder') ?? "John Smith"} size={'large'} style={{margin: '1rem 0'}} onChange={updateName} autoComplete="name" />
                    <div className={'upload-form'}>
                        <Upload
                            action={`https://im.nabli.net/api/Upload/${name}`}
                            listType="picture-card"
                            fileList={fileList}
                            accept={'image/*,video/*'}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>
                </div>
            </Content>
        </Layout>
    );
}

export default App;
